import { atomFamily } from "recoil";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../constants/CareESIO/CareEsioConstants";
import { ContentType } from "../../constants/Common/Constants";

export const atomSelectedRecipientRightContent = atomFamily<any, any>({
  key: "selectedRecipientRightSection",
  default: null,
});

export const atomSelectedRecipientRightButtonContent = atomFamily<any, any>({
  key: "selectedRecipientRightSectionButton",
  default: null,
});

export const atomSelectedCarePlanTabItem = atomFamily<any, any>({
  key: "selectedCarePlanTabItem",
  default: {
    text: CareEsioTab.PermanentCondition.CollectionName,
    value: CareEsioTab.PermanentCondition.Value,
  },
});

export const atomSelectedServiceTabItem = atomFamily<any, any>({
  key: "selectedServiceTabItem",
  default: { text: "Services", value: "Services" },
});

export const atomSelectedServiceTransactionId = atomFamily<any, any>({
  key: "selectedServiceTransactionId",
  default: null,
});

export const getClientByUID = (UID: string) => {
  return new Promise<{} | string>((resolve) => {
    const objData = {
      EntityId: UID,
      EntityName: CareEsioEntity.CareRecipient.EntityName,
    };
    repositoryActions
      .postDataAndGetResponse(
        "CareRecipient/RetrieveForm",
        objData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (response.status === 204) {
          resolve(null);
        } else {
          if (
            response.data &&
            response.data.ResultTable &&
            response.data.ResultTable.length > 0
          ) {
            resolve(response.data.ResultTable[0]);
          } else {
            resolve(null);
          }
        }
      });
  });
};
